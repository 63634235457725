export default {
  loadDataPager(queryClause) {
    return `
    query {
      readyStyles(${queryClause}) {
        id
        name
        index
        style
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createReadyStyles(${queryClause}) {
        id
        name
        index
        style
      }
    }
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateProperty(${queryClause}) {
        id
      }
    }    
    `
  },
  delete(whCls) {
    return `
    mutation {
      deleteReadyStyle(${whCls})
    }    
    `
  }
}
