import * as Yup from 'yup'
// constant
import validateError from '../constants/validate'

export default {
  stringRequired: Yup.string()
    .required(validateError.REQUIRED)
    .typeError(validateError.REQUIRED)
    .trim(validateError.REQUIRED),
  string: Yup.string().nullable(),
  numberRequired: Yup.number()
    .required(validateError.REQUIRED)
    .typeError(validateError.NUMBER),
  number: Yup.number().nullable(),

  array: Yup.array().nullable(),
  arrayRequired: Yup.array()
    .required(validateError.REQUIRED)
    .typeError(validateError.REQUIRED),
  emailRequired: Yup.string()
    .required(validateError.REQUIRED)
    .typeError(validateError.REQUIRED)
    .trim(validateError.REQUIRED)
    .email(validateError.EMAIL),
  email: Yup.string()
    .email(validateError.EMAIL)
    .nullable(),
  bool: Yup.bool().nullable(),
  objectRequired: Yup.object()
    .required(validateError.REQUIRED)
    .typeError(validateError.REQUIRED),
  object: Yup.object().nullable(),
  validateSchema: (schema, content) => !!schema.isValidSync(content)
}
