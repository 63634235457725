export default {
  loadDataPager(queryClause) {
    return `
    query {
      categories(${queryClause}) {
        id
        name
      }
    }    
    `
  }
}
