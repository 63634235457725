import React, { Fragment, useState, useEffect, useCallback } from 'react'
// lib
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { BackTop, Layout as AntLayout } from 'antd'
import { Spinner } from 'react-redux-spinner'
import { enquireScreen, unenquireScreen } from 'enquire-js'
import { ContainerQuery } from 'react-container-query'
import classNames from 'classnames'
import { isEqual } from 'lodash'

// routes
import screenWidth from '../../constants/screenWidth'
// component
import Notifications from '../Notification'
import Menu from './Menu'
import TopBar from './TopBar'
import AdminRoute from '../CustomRoute/AdminRoute'
import Breadcrumb from './TopBar/Breadcrumb'
import initMenus from '../../constants/menu'
import featureClause from '../../clauseSearch/feature'
import dataActions from '../../actions/dataActions'
import { selectFeatureCategory, selectFeatures } from '../../reselect/feature'
import { FEATURE_CATEGORY_PATH } from '../../constants/path'
import { usePrevious } from '../../hooks/usePrevious'
import {
  COOKIE_AUTH,
  COOKIE_REFRESH_AUTH,
  REFRESH_BEFORE_EXPIRE
} from '../../constants'
import { getCookie, getJSONCookie } from '../../extensions/cookie'
import {
  getNewDate,
  isFutureDate,
  isPreviousDate
} from '../../extensions/dateTime'
import authActions from '../../actions/authActions'
import { verifyToken } from '../../extensions/jwt'
import { selectTokenExpire } from '../../reselect/auth'
import { stringExtensions } from '../../extensions'
import readyStyleActions from "../../actions/readyStyleActions";
import featureActions from "../../actions/featureActions";

const AntContent = AntLayout.Content
const AntHeader = AntLayout.Header

let isMobile
enquireScreen((b) => {
  isMobile = b
})

const AdminArea = (props) => {
  const [menuMobileOpened, setMenuMobileOpened] = useState(false)
  const [menuCollapsed, setMenuCollapsed] = useState(false)
  const [themeLight, setThemeLight] = useState(true)
  const [settingsOpened, setSettingsOpened] = useState(false)
  const [mobileScreen, setMobileScreen] = useState(props.isMobile)

  const [navAdmin, setNavAdmin] = useState([])

  const features = useSelector(selectFeatures())
  const featureCategory = useSelector(selectFeatureCategory())

  const expiresIn = useSelector(selectTokenExpire())
  const refreshCookie = getCookie(COOKIE_REFRESH_AUTH)

  const dispatch = useDispatch()

  const loadFeatureCategoryCallback = useCallback(
    (queryClause) =>
      dispatch(dataActions.loadDataPager(queryClause, FEATURE_CATEGORY_PATH)),
    [dispatch]
  )

  const logoutCallback = useCallback(() => dispatch(authActions.logout()), [
    dispatch
  ])

  const refreshTokenCallback = useCallback(
    (queryClause) => dispatch(authActions.refreshToken(queryClause)),
    [dispatch]
  )


  const loadAllFCCallback = useCallback(
    () => dispatch(featureActions.loadFeatureCategory()),
    [dispatch]
  )

  const state = useSelector((state) => ({
    auth: state.auth
  }))

  useEffect(() => {
    setNavAdmin(initMenus())
    loadInitData()
  }, [])

  useEffect(() => {
    //loadListCategoriesCallback()
    loadAllFCCallback()
  }, [])

  useEffect(() => {
    enquireScreen((mobile) => setMobileScreen(mobile))
  }, [])

  useEffect(() => {
    return () => {
      unenquireScreen(enquireScreen((mobile) => setMobileScreen(mobile)))
    }
  }, [])

  const previousFeatureCategory = usePrevious(featureCategory)
  useEffect(() => {
    const hasChangeValue = !isEqual(previousFeatureCategory, featureCategory)
    if (hasChangeValue) {
      setNavAdmin(initMenus(features, featureCategory))
    }
  }, [featureCategory])

  useEffect(() => {
    if (isMobile) {
      setMenuMobileOpened(false)
      setMenuCollapsed(true)
    }
  }, [props.location])

  useEffect(() => {
    window.clearTimeout()
    if (expiresIn) {
      if (isFutureDate(expiresIn, getNewDate())) {
        const timeOut =
          getNewDate(expiresIn) - getNewDate() - REFRESH_BEFORE_EXPIRE
        console.log('timeOut.....', timeOut)
        if (timeOut > 0) {
          setTimeout(() => {
            handleRefreshToken()
          }, timeOut)
        } else {
          handleRefreshToken()
        }
      } else {
        console.log('log out.....')
        logoutCallback()
      }
    }
  }, [refreshCookie, expiresIn])

  const handleRefreshToken = () => {
    const refreshToken = stringExtensions.removeEscapeCharacter(refreshCookie)
    const queryClause = `token: "${refreshToken}"`
    refreshTokenCallback(queryClause)
  }

  const loadInitData = () => {
    loadFeatureCategoryCallback(featureClause.initQueryFeatureCategory())
  }

  const handleToggleMenuMobileOpen = () => {
    setMenuMobileOpened(!menuMobileOpened)
  }

  const handleMenuMobileOpen = () => {
    setMenuMobileOpened(false)
  }

  const handleToggleSettingOpened = () => {
    setSettingsOpened(!settingsOpened)
  }

  const handleMenuCollapseMobile = () => {
    setMenuCollapsed(false)
  }

  const handleToggleMenuCollapse = () => {
    setMenuCollapsed(!menuCollapsed)
  }

  const { history, location } = props
  const isMobile = !!mobileScreen
  const pathname = location?.pathname
  const auth = state?.auth
  const isAuthorization = getCookie(COOKIE_AUTH)

  return (
    <Fragment>
      <ContainerQuery query={screenWidth}>
        {(params) => (
          <div className={classNames(params)}>
            <AntLayout
              className={classNames('flex-row', {
                settings__borderLess: true,
                settings__squaredBorders: false,
                settings__fixedWidth: false,
                settings__menuShadow: true,
                settings__menuTop: false
              })}
            >
              <Spinner />
              <BackTop />
              {isAuthorization ? (
                <Menu
                  themeLight={themeLight}
                  isMobile={isMobile}
                  auth={auth}
                  pathname={pathname}
                  menuMobileOpened={menuMobileOpened}
                  menuCollapsed={menuCollapsed}
                  navAdmin={navAdmin}
                  handleToggleMenuMobileOpen={handleToggleMenuMobileOpen}
                  handleMenuMobileOpen={handleMenuMobileOpen}
                  handleToggleSettingOpened={handleToggleSettingOpened}
                  handleMenuCollapseMobile={handleMenuCollapseMobile}
                  handleToggleMenuCollapse={handleToggleMenuCollapse}
                />
              ) : null}
              <AntLayout>
                {isAuthorization && (
                  <AntHeader>
                    <TopBar
                      {...props}
                      auth={auth}
                      history={history}
                      isMobile={isMobile}
                      navAdmin={navAdmin}
                    />
                  </AntHeader>
                )}
                <AntContent style={{ height: '100%', position: 'relative' }}>
                  {isAuthorization ? (
                    <Breadcrumb
                      {...props}
                      auth={auth}
                      history={history}
                      isMobile={isMobile}
                      navAdmin={navAdmin}
                    />
                  ) : null}
                  <div
                    className={classNames({ utils__content: isAuthorization })}
                  >
                    <AdminRoute {...props} auth={auth} isMobile={isMobile} />
                  </div>
                </AntContent>
              </AntLayout>
            </AntLayout>
          </div>
        )}
      </ContainerQuery>
      <Notifications />
    </Fragment>
  )
}

export default withRouter(AdminArea)
