import {reducer as notifications} from 'react-notification-system-redux'
import {connectRouter} from 'connected-react-router'
import {combineReducers} from 'redux'
import data from './data'
import product from './product'
import common from './common'
import auth from './auth'
import apiError from './apiError'
import feature from './feature'
import readyStyle from './readyStyles'

export const deepCopyState = (value) => JSON.parse(JSON.stringify(value))

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    data,
    product,
    common,
    auth,
    apiError,
    feature,
    readyStyle,
  })

export default rootReducer
