export default {
  login(queryClause) {
    return `
    mutation {
      loginAdmin(${queryClause}) {
        token
        refreshToken
        expiresIn
        refreshExpiresIn
      }
    }    
    `
  },

  refreshToken(queryClause) {
    return `
    mutation {
      refreshToken(${queryClause}) {
        token
        refreshToken
        expiresIn
        refreshExpiresIn
      }
    }    
    `
  },

  changePassword(queryClause) {
    return `
    mutation {
      changePassword(${queryClause})
    }    
    `
  },

  changeUserPassword(queryClause) {
    return `
    mutation {
      changeUserPassword(${queryClause})
    }    
    `
  },

  changePassCode(queryClause) {
    return `
    mutation {
      updateSetting(${queryClause}){
        id
        value
      }
    }    
    `
  }
}
