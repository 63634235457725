import React from 'react'
import {
  UngroupOutlined,
  OrderedListOutlined,
  ApartmentOutlined,
  ClusterOutlined
} from '@ant-design/icons'
import { routes, resources } from '../routes'
import mainRoute from '../routes/mainRoute'
import { FEATURE_TYPE } from './enumType'

const initMenus = (features, featureCategories) => {
  const menus = [
    {
      name: resources.PRODUCT_LIST.title,
      key: resources.PRODUCT_LIST.title,
      resource: resources.PRODUCT_LIST.key,
      path: routes.PRODUCT_LIST,
      icon: <UngroupOutlined />
    }
  ]

  if (featureCategories?.length > 0) {
    featureCategories.map((category) => {
      Object.values(FEATURE_TYPE).forEach((value) => {
        menus.push({
          name: category.name,
          key: `${value}-${category.id}`,
          resource: resources.FEATURE_LIST.key,
          icon: <ClusterOutlined />,
          path: `${routes.FEATURE_CATEGORY}/${value.toLowerCase()}/${
            category.id
          }`
        })
      })
    })
  }

  menus.push({
    name: resources.FINISH_LIST.title,
    key: resources.FINISH_LIST.title,
    resource: resources.FINISH_LIST.key,
    path: routes.FINISH_LIST,
    icon: <OrderedListOutlined />
  })

  menus.push({
    name: resources.READY_STYLE_LIST.title,
    key: resources.READY_STYLE_LIST.title,
    resource: resources.READY_STYLE_LIST.key,
    path: routes.READY_STYLE_LIST,
    icon: <OrderedListOutlined />
  })

  return menus
}

export const MENU_PROFILE = {
  ADMIN_PASS: {
    key: 'admin-pass',
    name: 'Change Admin Password'
  },
  // GUEST_PASS: {
  //   key: 'guest-pass',
  //   name: 'Change Guest Password'
  // },
  PASSCODE: {
    key: 'passcode',
    name: 'Change Passcode'
  },
  // SAVE_SETTING: {
  //   key: 'save-setting',
  //   name: 'Save Setting'
  // },
  EXPORT_ADMIN_LAYOUT: {
    key: 'export_admin_layout',
    name: 'Export admin layout'
  },
  IMPORT_ADMIN_LAYOUT: {
    key: 'import_admin_layout',
    name: 'Import admin layout'
  },
  LOGOUT: {
    key: 'logout',
    name: 'Logout'
  }
}

export default initMenus
