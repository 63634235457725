export default {
  HOME_PAGE: '/',
  CATEGORY: '/category',
  PRODUCT: '/product',
  PROPERTIES: '/properties',
  AUTHENTICATE: '/auth',
  FEATURE_PRODUCT: '/feature-product',
  FINISH_LIST: '/finish-list',
  FEATURE_CATEGORY: '/feature-cat',
  READY_STYLE: '/ready-style'
}
