import category from './category'
import product from './product'
import property from './property'
import feature from './feature'
import featureProduct from './featureProduct'
import featureCategory from './featureCategory'
import menuFeature from './menu'
import auth from './auth'
import finishList from './finishList'
import readyStyle from './readyStyle'
// SELECT
import selectCategory from './select/category'
import selectFinishList from './select/finishList'

export default {
  auth,
  category,
  product,
  property,
  feature,
  featureProduct,
  featureCategory,
  menuFeature,
  finishList,
  readyStyle,
  // SELECT
  selectCategory,
  selectFinishList
}
