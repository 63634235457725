import ActionTypes from '../actions/types'

export const CHANGE_READY_STYLE = 'fetch/CHANGE_READY_STYLE'

const initialState = {
  activeReadyStyle: 0,
  readyStyles: [],
  currentReadyStyle: null
}

const ReadyStyleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_READY_STYLE: {
      if (!action.payload.data) return state
      const listReadyStyles = action.payload.data
      return Object.assign({}, state, {
        readyStyles: listReadyStyles,
        currentReadyStyle: listReadyStyles[0],
        activeReadyStyle: 0
      })
    }
    case ActionTypes.CREATE_READY_STYLE_SUCCESS: {
      if (!action.payload.data) return state
      const listReadyStyles = action.payload.data
      let currentReadyStyle = listReadyStyles.find(item => item.index === state.activeReadyStyle)
      if(!currentReadyStyle) currentReadyStyle = listReadyStyles[0]
      return Object.assign({}, state, {
        readyStyles: listReadyStyles,
        currentReadyStyle: currentReadyStyle,
        activeReadyStyle: currentReadyStyle.index
      })
    }

    case ActionTypes.CLEAR_READY_STYLE: {
      return initialState
    }

    case ActionTypes.ADD_NEW_READY_STYLE: {
      if (!action.payload.data) return state
      const { addition, data = {} } = action.payload.data
      let add = addition ? Number(addition) : 1
      if (!add) add = 1

      const listReadyStyles = JSON.parse(JSON.stringify(state.readyStyles)) || []

      let lastIndex = 0

      if (listReadyStyles?.length > 0) {
        const indexes = listReadyStyles.map((t) => Number(t.index) ?? 0)
        lastIndex = Math.max(...indexes)
      }
      const listNewReadyStyles = []
      for (let index = 0; index < add; index++) {
        const readyStyleIndex = (Number(lastIndex) ?? 0) + index + 1

        const newReadyStyle = {
          ...data,
          name: `New style`,
          index: Number(readyStyleIndex)
        }

        listNewReadyStyles.push(newReadyStyle)
      }
      lastIndex = (Number(lastIndex) ?? 0) + 1
      listReadyStyles.push(...listNewReadyStyles)

      return Object.assign({}, state, {
        readyStyles: listReadyStyles,
        currentReadyStyle: listNewReadyStyles[0],
        activeReadyStyle: Number(lastIndex)
      })
    }

    case ActionTypes.SET_ACTIVE_READY_STYLE: {
      if (!action.payload.data) return state
      const activeKey = Number(action.payload.data)

      const currentReadyStyle =
        state.readyStyles?.length > 0
          ? state.readyStyles.find((t) => t.index === activeKey) ?? null
          : null
      return Object.assign({}, state, {
        currentReadyStyle: currentReadyStyle,
        activeReadyStyle: activeKey
      })
    }

    case ActionTypes.REMOVE_CURRENT_READY_STYLE: {
      if (!action.payload.data) return state

      const removeKey = Number(action.payload.data)

      const listReadyStyles = (state.readyStyles ?? []).filter(
        (pane) => pane.index !== removeKey
      )

      let currentActiveKey = 0
      const indexes = listReadyStyles.map((t) => t.index)
      if (indexes.length > 0) {
        const smallerIndexes = indexes.filter((x) => x < removeKey)
        if (smallerIndexes.length > 0) {
          currentActiveKey = Math.max(...smallerIndexes)
        } else {
          const largerIndexes = indexes.filter((x) => x > removeKey)
          currentActiveKey = Math.min(...largerIndexes)
        }

        if (!currentActiveKey) {
          currentActiveKey = 0
        }
      }

      const currentReadyStyle =
        listReadyStyles.find((t) => t.index === currentActiveKey) ?? null

      return Object.assign({}, state, {
        readyStyles: listReadyStyles,
        activeReadyStyle: Number(currentActiveKey),
        currentReadyStyle: currentReadyStyle
      })
    }

    case ActionTypes.SAVE_CURRENT_READY_STYLE: {
      if (!action.payload.data) return state
      const { index, name } = action.payload.data

      const listReadyStyles = state.readyStyles ?? []

      const indexItem = listReadyStyles.findIndex((item) => item.index === index)

      console.log('indexItem..........', indexItem)

      const currentReadyStyles = listReadyStyles[indexItem >= 0 ? indexItem : 0]

      console.log('currentReadyStyles..........', currentReadyStyles)

      listReadyStyles.splice(indexItem, 1, {
        ...listReadyStyles[indexItem],
        name: name
      })

      return Object.assign({}, state, {
        readyStyles: listReadyStyles,
        currentReadyStyle: currentReadyStyles
      })
    }

    case CHANGE_READY_STYLE: {
      if (!action.payload.data) return state

      const { style, name } = action.payload.data

      const currentReadyStyle = state.currentReadyStyle

      currentReadyStyle.style = style ?? null
      currentReadyStyle.name = name ?? 'New Style'
      
      const listReadyStyles = state.readyStyles

      const indexReadyStyle = listReadyStyles.findIndex((t) => t.index === state.activeReadyStyle)
      if (indexReadyStyle >= 0) {
        listReadyStyles[indexReadyStyle] = currentReadyStyle
      }

      return Object.assign({}, state, {
        currentReadyStyle: currentReadyStyle,
        readyStyles: listReadyStyles
      })
    }

    default:
      return state
  }
}

export default ReadyStyleReducer
