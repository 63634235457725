export { default as formikExtensions } from './formik'
export { default as immutableExtensions } from './immutable'
export { default as numberExtensions } from './number'
export { default as objectExtensions } from './object'
export { default as queryStringExtensions } from './queryString'
export { default as stringExtensions } from './string'
export { default as yupExtensions } from './yup'

export default {
  deleteEmptyProps(obj) {
    return Object.keys(obj).forEach(
      (key) => (obj[key] === null || obj[key] === '') && delete obj[key]
    )
  }
}
