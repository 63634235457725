import mainRoute from './mainRoute'
import LoadableComponent from '../components/LoadableComponent'

export const routes = {
  /**
   * CATEGORY
   */
  CATEGORY_LIST: `${mainRoute.CATEGORY}`,
  CATEGORY_EDIT: `${mainRoute.CATEGORY}/:id`,
  CATEGORY_CREATE: `${mainRoute.CATEGORY}/create`,
  /**
   * END CATEGORY
   */

  /**
   * PRODUCT
   */
  PRODUCT_LIST: `${mainRoute.PRODUCT}`,
  PRODUCT_EDIT: `${mainRoute.PRODUCT}/:id`,
  PRODUCT_CREATE: `${mainRoute.PRODUCT}/create`,
  PRODUCT_PROPERTY: `${mainRoute.PRODUCT}/props`,
  /**
   * END PRODUCT
   */

  /**
   * PROPERTIES
   */
  PROPERTIES_LIST: `${mainRoute.PROPERTIES}`,
  PROPERTIES_EDIT: `${mainRoute.PROPERTIES}/:id`,
  PROPERTIES_CREATE: `${mainRoute.PROPERTIES}/create`,
  /**
   * END PROPERTIES
   */

  FEATURE_CATEGORY: `${mainRoute.FEATURE_CATEGORY}`,
  FEATURE_LIST_TYPE: `${mainRoute.FEATURE_CATEGORY}/:type/:id`,
  FINISH_LIST: `${mainRoute.FINISH_LIST}`,

  /**
   * Feature product
   */
  FEATURE_PRODUCT_LIST: `${mainRoute.FEATURE_PRODUCT}/:featureId`,
  FEATURE_PRODUCT_EDIT: `${mainRoute.FEATURE_PRODUCT}/:featureId/edit/:id`,
  FEATURE_PRODUCT_CREATE: `${mainRoute.FEATURE_PRODUCT}/:featureId/create`,

  /**
   * Feature product
   */
  READY_STYLE_LIST: `${mainRoute.READY_STYLE}`,
  /**
   * END PROPERTIES
   */

  LOGIN: `${mainRoute.AUTHENTICATE}/login`
}

export const resources = {
  /**
   * CATEGORY
   */
  CATEGORY_LIST: {
    title: 'Categories',
    key: 'CATEGORY_LIST'
  },
  CATEGORY_EDIT: {
    title: 'Edit Category',
    key: 'CATEGORY_EDIT'
  },
  CATEGORY_CREATE: {
    title: 'Create Category',
    key: 'CATEGORY_CREATE'
  },
  /**
   * END CATEGORY
   */

  /**
   * PRODUCT
   */
  PRODUCT_LIST: {
    title: 'Products',
    key: 'PRODUCT_LIST'
  },
  PRODUCT_EDIT: {
    title: 'Edit Product',
    key: 'PRODUCT_EDIT'
  },
  PRODUCT_CREATE: {
    title: 'Create Product',
    key: 'PRODUCT_CREATE'
  },
  PRODUCT_PROPERTY: {
    title: 'List Product Properties',
    key: 'PRODUCT_PROPERTY'
  },
  /**
   * END PRODUCT
   */

  /**
   * PROPERTIES
   */
  PROPERTIES_LIST: {
    title: 'Properties',
    key: 'PROPERTIES_LIST'
  },
  PROPERTIES_EDIT: {
    title: 'Edit Category',
    key: 'PROPERTIES_EDIT'
  },
  PROPERTIES_CREATE: {
    title: 'Create Category',
    key: 'PROPERTIES_CREATE'
  },
  /**
   * END CATEGORY
   */

  FEATURE_LIST: {
    title: 'Feature',
    key: 'FEATURE_LIST'
  },
  FEATURE_CREATE: {
    title: 'Create Feature',
    key: 'FEATURE_CREATE'
  },
  FEATURE_EDIT: {
    title: 'Edit Feature',
    key: 'FEATURE_EDIT'
  },

  FINISH_LIST: {
    title: 'Finish List',
    key: 'FINISH_LIST'
  },
  FINISH_LIST_CREATE: {
    title: 'Create Finish List',
    key: 'FINISH_LIST_CREATE'
  },
  FINISH_LIST_EDIT: {
    title: 'Edit Finish List',
    key: 'FINISH_LIST_EDIT'
  },

  /**
   * PROPERTIES
   */
  FEATURE_PRODUCT_LIST: {
    title: 'Feature',
    key: 'FEATURE_PRODUCT_LIST'
  },
  FEATURE_PRODUCT_EDIT: {
    title: 'Edit feature product',
    key: 'FEATURE_PRODUCT_EDIT'
  },
  FEATURE_PRODUCT_CREATE: {
    title: 'Create feature product',
    key: 'FEATURE_PRODUCT_CREATE'
  },

  READY_STYLE_LIST: {
    title: 'Ready style',
    key: 'READY_STYLE_LIST'
  },
  /**
   * END CATEGORY
   */

  LOGIN: {
    title: 'Login',
    key: 'LOGIN'
  }
}

export const navigation = [
  {
    name: resources.CATEGORY_CREATE.title,
    key: resources.CATEGORY_CREATE.key,
    resource: resources.CATEGORY_CREATE.key,
    path: routes.CATEGORY_CREATE,
    component: LoadableComponent(() => import('../views/Category/create')),
    isMenu: true
  },
  {
    name: resources.CATEGORY_EDIT.title,
    key: resources.CATEGORY_EDIT.key,
    resource: resources.CATEGORY_EDIT.key,
    path: routes.CATEGORY_EDIT,
    component: LoadableComponent(() => import('../views/Category/edit')),
    isMenu: true
  },
  {
    name: resources.CATEGORY_LIST.title,
    key: resources.CATEGORY_LIST.key,
    resource: resources.CATEGORY_LIST.key,
    path: routes.CATEGORY_LIST,
    component: LoadableComponent(() => import('../views/Category')),
    isMenu: true
  },
  {
    name: resources.PRODUCT_CREATE.title,
    key: resources.PRODUCT_CREATE.key,
    resource: resources.PRODUCT_CREATE.key,
    path: routes.PRODUCT_CREATE,
    component: LoadableComponent(() => import('../views/Product/create')),
    isMenu: true
  },
  {
    name: resources.PRODUCT_EDIT.title,
    key: resources.PRODUCT_EDIT.key,
    resource: resources.PRODUCT_EDIT.key,
    path: routes.PRODUCT_EDIT,
    component: LoadableComponent(() => import('../views/Product/edit')),
    isMenu: true
  },
  {
    name: resources.PRODUCT_LIST.title,
    key: resources.PRODUCT_LIST.key,
    resource: resources.PRODUCT_LIST.key,
    path: routes.PRODUCT_LIST,
    component: LoadableComponent(() => import('../views/Product')),
    isMenu: true
  },
  {
    name: resources.PROPERTIES_CREATE.title,
    key: resources.PROPERTIES_CREATE.key,
    resource: resources.PROPERTIES_CREATE.key,
    path: routes.PROPERTIES_CREATE,
    component: LoadableComponent(() => import('../views/Properties/create')),
    isMenu: true
  },
  {
    name: resources.PROPERTIES_EDIT.title,
    key: resources.PROPERTIES_EDIT.key,
    resource: resources.PROPERTIES_EDIT.key,
    path: routes.PROPERTIES_EDIT,
    component: LoadableComponent(() => import('../views/Properties/edit')),
    isMenu: true
  },
  {
    name: resources.PROPERTIES_LIST.title,
    key: resources.PROPERTIES_LIST.key,
    resource: resources.PROPERTIES_LIST.key,
    path: routes.PROPERTIES_LIST,
    component: LoadableComponent(() => import('../views/Properties')),
    isMenu: true
  },
  {
    name: resources.FEATURE_PRODUCT_CREATE.title,
    key: resources.FEATURE_PRODUCT_CREATE.key,
    resource: resources.FEATURE_PRODUCT_CREATE.key,
    path: routes.FEATURE_PRODUCT_CREATE,
    component: LoadableComponent(() =>
      import('../views/FeatureProduct/create')
    ),
    isMenu: true
  },
  {
    name: resources.FEATURE_PRODUCT_EDIT.title,
    key: resources.FEATURE_PRODUCT_EDIT.key,
    resource: resources.FEATURE_PRODUCT_EDIT.key,
    path: routes.FEATURE_PRODUCT_EDIT,
    component: LoadableComponent(() => import('../views/FeatureProduct/edit')),
    isMenu: true
  },
  {
    name: resources.FEATURE_PRODUCT_LIST.title,
    key: resources.FEATURE_PRODUCT_LIST.key,
    resource: resources.FEATURE_PRODUCT_LIST.key,
    path: routes.FEATURE_PRODUCT_LIST,
    component: LoadableComponent(() => import('../views/FeatureProduct')),
    isMenu: true
  },
  {
    name: resources.FEATURE_PRODUCT_LIST.title,
    key: resources.FEATURE_PRODUCT_LIST.key,
    resource: resources.FEATURE_PRODUCT_LIST.key,
    path: routes.FEATURE_LIST_TYPE,
    component: LoadableComponent(() => import('../views/FeatureCategory')),
    isMenu: false
  },
  {
    name: resources.FINISH_LIST.title,
    key: resources.FINISH_LIST.key,
    resource: resources.FINISH_LIST.key,
    path: routes.FINISH_LIST,
    component: LoadableComponent(() => import('../views/FinishList')),
    isMenu: false
  },
  {
    name: resources.READY_STYLE_LIST.title,
    key: resources.READY_STYLE_LIST.key,
    resource: resources.READY_STYLE_LIST.key,
    path: routes.READY_STYLE_LIST,
    component: LoadableComponent(() => import('../views/ReadyStyle')),
    isMenu: false
  }
]

export const navAuth = [
  {
    name: resources.LOGIN.title,
    key: resources.LOGIN.key,
    resource: resources.LOGIN.key,
    path: routes.LOGIN,
    component: LoadableComponent(() => import('../views/Login')),
    isMenu: false,
    isProtected: false
  }
]
