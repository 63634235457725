import { createSelector } from 'reselect'
import {
  FEATURE_CATEGORY_PATH,
  FEATURE_PATH,
  MENU_FEATURE_PATH
} from '../constants/path'

const getData = (state) => (state.data ? state.data : null)

const getAllFC = (state) => state.feature?.categories ?? []

const getAllFP = (state) => state.feature?.products ?? []

const getAllFL = (state) => state.feature?.finishLists ?? []

const getCategorySelected = (state) => state.feature?.categorySelected ?? []

const getFeatureSelected = (state) => state.feature?.featureSelected ?? []

const getProductSelected = (state) => state.feature?.productSelected ?? []

const getLoadingProduct = (state) => state.feature?.loadingFP

const getLoadingFinishList = (state) => state.feature?.loadingFL

const getFeatures = (data) => {
  if (!data) return []
  return data.features ? data.features || [] : []
}

const getFeatureDetail = (data, id) => {
  if (!data || !id) return null
  const features = data.features ? data.features || [] : []
  if (features && features.length > 0) {
    return features.find((item) => item.id === id)
  }
}

const getFeatureCategory = (data) => {
  if (!data) {
    return []
  }

  return data?.featureCategories ?? []
}

export const selectFeatures = () =>
  createSelector([getData], (data) => {
    return getFeatures(data ? data.get(MENU_FEATURE_PATH) : null)
  })

export const selectFeature = (id) =>
  createSelector([getData], (data) => {
    return getFeatureDetail(data ? data.get(FEATURE_PATH) : null, id)
  })

export const selectFeatureCategory = () =>
  createSelector([getData], (data) => {
    const featureCategories = getFeatureCategory(
      data?.get(FEATURE_CATEGORY_PATH)
    )
    return featureCategories
  })

export const selectLoadingProduct = () =>
  createSelector([getLoadingProduct], (data) => !!data)

export const selectLoadingFinishList = () =>
  createSelector([getLoadingFinishList], (data) => !!data)

export const selectAllFC = () => createSelector([getAllFC], (data) => data)

export const selectAllFP = () => createSelector([getAllFP], (data) => data)

export const selectAllFL = () => createSelector([getAllFL], (data) => data)

export const selectProductByFeature = (featureId) =>
  createSelector([getAllFP], (data) => {
    if (!featureId) return []
    return data?.filter((fp) => fp.featureId === featureId) ?? []
  })

export const selectFinishListByProduct = (productId) =>
  createSelector([getAllFL], (data) => {
    if (!productId) return []
    return data?.filter((fp) => fp.productId === productId) ?? []
  })

export const selectCategorySelected = () =>
  createSelector([getCategorySelected], (data) => data)

export const selectFeatureSelected = () =>
  createSelector([getFeatureSelected], (data) => data)

export const selectProductSelected = () =>
  createSelector([getProductSelected], (data) => data)
