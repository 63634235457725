import finishList from '../actions/queries/finishList'
import { queryStringExtensions, stringExtensions } from '../extensions'
export default {
  initQueryFeatures(values = {}) {
    let query = `isDeleted: false `
    if (values.keyword) {
      const keyword = stringExtensions.removeEscapeCharacter(values.keyword)
      query += `, name: {like: "%${keyword}%"}`
    }
    if (values.type) {
      query += `, type: ${values.type}`
    }
    if (values.featureCategoryId) {
      query += `, featureCategoryId: "${stringExtensions.removeEscapeCharacter(
        values.featureCategoryId
      )}"`
    }
    let order = `order`
    return {
      whereClause: `where: {${query}}, order: "${order}"`,
      whereClauseConnection: `where: {${query}}, first: 1`
    }
  },

  initQueryFeatureCategory() {
    const query = `isPublished: true, isDeleted: false`
    return `where: {${query}}, order: "createdAt"`
  },

  initQueryUniqueFeature(id) {
    return `id: "${stringExtensions.removeEscapeCharacter(id)}"`
  },

  initQueryDeleteFeature(id) {
    const query = `isDeleted: true`
    const queryWhere = this.initQueryUniqueFeature(id)
    return `data: {${query}}, where: {${queryWhere}}`
  },

  initQueryCreateOrUpdateFeature({
    values,
    featureCatId,
    featureId,
    isEditRow = false,
    type
  }) {
    let query = ``
    let isEditProduct = false
    let queryProducts = '[]'

    if (featureCatId) {
      const featureCategoryId = stringExtensions.removeEscapeCharacter(
        featureCatId
      )
      query += `, featureCategoryId: "${featureCategoryId}"`
    }

    if (type) {
      const featureType = stringExtensions.removeEscapeCharacter(type)
      query += `, type: ${stringExtensions.capitalizeString(featureType)}`
    }

    if (values.name) {
      const name = stringExtensions.removeEscapeCharacter(values.name)
      query += `, name: "${name}"`
    }

    if (typeof values.isPublished === 'boolean') {
      query += `, isPublished: ${!!values.isPublished}`
    } else {
      query += `, isPublished: true`
    }

    if (values.order) {
      query += `, order: ${values.order}`
    } else {
      query += `, order: 1`
    }

    if (!isEditRow) {
      if (values.description) {
        const description = stringExtensions.removeEscapeCharacter(
          values.description
        )
        query += `, description: "${description}"`
      } else {
        query += `, description: null`
      }

      if (values.images) {
        query += `, images: "${values.images.filename}"`
      } else {
        query += `, images: null`
      }

      isEditProduct = true
      queryProducts = `[`
      if (values.materialOptions?.length > 0) {
        values.materialOptions.forEach((option) => {
          queryProducts += `{`
          if (option.id) {
            const id = stringExtensions.removeEscapeCharacter(option.id)
            queryProducts += `, id: "${id}"`
          }

          if (option.name) {
            const name = stringExtensions.removeEscapeCharacter(option.name)
            queryProducts += `, name: "${name}"`
          } else {
            queryProducts += `, name: null`
          }

          if (option.description) {
            const description = stringExtensions.removeEscapeCharacter(
              option.description
            )
            queryProducts += `, description: "${description}"`
          } else {
            queryProducts += `, description: null`
          }

          if (option.images) {
            queryProducts += `, images: "${option.images.filename}"`
          } else {
            queryProducts += `, images: null`
          }

          if (typeof option.isPublished === 'boolean') {
            queryProducts += `, isPublished: ${!!option.isPublished}`
          } else {
            queryProducts += `, isPublished: true`
          }

          if (option.order) {
            queryProducts += `, order: ${option.order}`
          } else {
            queryProducts += `, order: 1`
          }

          queryProducts += `, finishList: [`
          if (option.finishList?.length > 0) {
            option.finishList.forEach((finishList) => {
              const id = stringExtensions.removeEscapeCharacter(
                finishList.value
              )
              queryProducts += `, "${id}"`
            })
          }
          queryProducts += `]`

          queryProducts += `}`
        })
      }

      queryProducts += `]`
    }

    const queryWhere = this.initQueryUniqueFeature(featureId)

    return featureId
      ? `data: {${query}}, where: {${queryWhere}}, featureProducts: ${queryProducts}, isUpdateProduct: ${isEditProduct}`
      : `data: {${query}}`
  }
}
