import React from 'react'
import PropTypes from 'prop-types'
import { getIn, useFormikContext } from 'formik'

const ErrorMessage = (props) => {
  const { fieldName, isValidate = true } = props

  const { errors, touched } = useFormikContext()

  const hasError = getIn(errors, fieldName)?.trim() && getIn(touched, fieldName)
  return isValidate && hasError ? (
    <div className='custom-error'>{getIn(errors, fieldName)}</div>
  ) : null
}

ErrorMessage.propTypes = {
  fieldName: PropTypes.string,
  isValidate: PropTypes.bool
}

export default ErrorMessage
