export const DOMAIN = process.env.REACT_APP_DOMAIN

export const GRAPHQL_PATH = ''

export const DEFAULT_PAGE_INDEX = 1

export const DEFAULT_PAGE_SIZE = 25

export const DEFAULT_PROP_DESCRIPTION_ROW = 5

export const EXPIRES_DAY_COOKIES = 7

export const REFRESH_BEFORE_EXPIRE = 60 * 1000

export const DEFAULT_THUMBNAIL = require('../img/default-images.png')

export const UPLOAD_PRODUCT = 'uploads/products'

export const IMAGE_LINK = 'images'

export const COOKIE_AUTH = 'ad_token'

export const COOKIE_REFRESH_AUTH = 'ad_refresh_token'

export const GUEST = 'Guest'

export const GUEST_EMAIL_ACC = 'guest@happycabinetdesigner.com'

export const CODE_PASSCODE = 'PASSCODE'

export const PAGINATION_ALL = 'all'
