export const COL_INDEX = 'Index'
export const COL_NAME = 'Name'
export const COL_STATUS = 'Status'
export const COL_ACTION = 'Action'
export const COL_CATEGORY = 'Category'
export const COL_DESCRIPTION = 'Description'
export const COL_DEFAULT_DISPLAY = 'Default Display'
export const COL_PARENT = 'Parent'
export const COL_ORDER = 'Order'

export const PAGE_CATEGORY = 'List Categories'
export const SINGLE_CATEGORY = 'category'
export const PAGE_PRODUCT = 'List Products'
export const SINGLE_PRODUCT = 'product'
export const PAGE_PROPERTIES = 'List Properties'
export const SINGLE_PROPERTY = 'properties'
export const SINGLE_FEATURE_PRODUCT = 'feature products'
export const SINGLE_FINISH_LIST = 'finish list'

export const BUTTON_CREATE = 'Create'
export const BUTTON_CANCEL = 'Cancel'
export const BUTTON_SAVE = 'Save'
export const BUTTON_DELETE = 'Delete'
export const BUTTON_RESTORE = 'Restore'
export const BUTTON_EDIT = 'Edit'
export const BUTTON_EXPAND_ALL = 'Expand All'
export const BUTTON_COLLAPSE_ALL = 'Collapse All'
export const BUTTON_ADD_VARIABLE = 'Add Variable'
export const BUTTON_ADD = 'Add'
export const BUTTON_ADD_OPTION = 'Add Option'
export const BUTTON_LOGIN = 'Login'
export const BUTTON_UPLOAD = 'Upload'
export const BUTTON_DOWNLOAD_TEMPLATE = 'Download Template'

export const LABEL_NAME = 'Name'
export const LABEL_PARENT = 'Parent'
export const LABEL_CATEGORY = 'Category'
export const LABEL_SHORT_DESCRIPTION = 'Short Description'
export const LABEL_DESCRIPTION = 'Description'
export const LABEL_PUBLISHED = 'Published'
export const LABEL_SHOW_DEFAULT = 'Show Default'
export const LABEL_DEFAULT_VALUE = 'Default Value'
export const LABEL_ORDER = 'Order'
export const LABEL_KEY = 'Key'
export const LABEL_IMAGES = 'Images'
export const LABEL_VARIABLE_LIMIT = 'Millimeter Limit'
export const LABEL_VARIABLE_INCH_LIMIT = 'Inch Limit'
export const LABEL_MATERIAL_OPTION = 'Material Options'
export const LABEL_FINISH_LIST = 'Finish List'
export const LABEL_EMAIL = 'Email'
export const LABEL_PASSWORD = 'Password'
export const LABEL_OLD_PASSWORD = 'Old Password'
export const LABEL_NEW_PASSWORD = 'New Password'
export const LABEL_CONFIRM_PASSWORD = 'Confirm Password'
export const LABEL_NEW_PASSCODE = 'New Passcode'

export const PLACEHOLDER_CATEGORY = 'Select Category'
export const PLACEHOLDER_FINISH_LIST = 'Select Finish List'
