import ActionTypes from '../actions/types'

const initialState = {
  user: null,
  expiresIn: null
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGGED_IN: {
      return action.payload.data
    }
    case ActionTypes.USER_LOGGED_OUT: {
      return initialState
    }
    case ActionTypes.REFRESH_TOKEN: {
      return Object.assign({}, state, {
        expiresIn: action.payload.data
      })
    }
    default:
      return state
  }
}

export default AuthReducer
