import { getIn } from 'formik'
import _ from 'lodash'
import { getPreviewImage } from './file'

export default {
  checkFieldError({ errors, touched, fieldName }) {
    return !!(getIn(errors, fieldName) && getIn(touched, fieldName))
  },

  getDefaultValueField({ data, fieldName, defaultFieldValue }) {
    if (!data || (typeof data[fieldName] !== 'boolean' && !data[fieldName])) {
      return defaultFieldValue ? defaultFieldValue : null
    }
    return data[fieldName]
  },

  getObjectValueField({ rawValue, valueField, labelField }) {
    if (rawValue) {
      return {
        ...rawValue,
        key: valueField ? rawValue[valueField] : rawValue._id,
        value: valueField ? rawValue[valueField] : rawValue._id,
        label: labelField ? rawValue[labelField] : rawValue.name
      }
    }
    return null
  },

  getListObjectValueField({ data, fieldName, labelField, valueField }) {
    if (!data || !data[fieldName]) {
      return []
    }

    return data[fieldName].map((item) =>
      this.getObjectValueField({
        rawValue: item,
        labelField: labelField,
        valueField: valueField
      })
    )
  },

  preventEnterSubmitForm(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  },

  getImageValueField(data, fieldName) {
    if (!data || !data[fieldName]) {
      return null
    }

    const fileName = _.isArray(data[fieldName])
      ? data[fieldName][0]
      : data[fieldName]
    return {
      uid: null,
      status: 'done',
      filename: fileName,
      url: getPreviewImage(fileName)
    }
  }
}
