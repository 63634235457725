import ActionTypes from '../actions/types'

const initState = {
  loading: false,
  changePassword: false
}

const CommonReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.START_FETCHING: {
      return Object.assign(state, {
        loading: true
      })
    }
    case ActionTypes.STOP_FETCHING: {
      return Object.assign(state, {
        loading: false
      })
    }
    case ActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        changePassword: true
      })
    }
    case ActionTypes.RESET_CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        changePassword: false
      })
    }
    default: {
      return state
    }
  }
}

export default CommonReducer
