export const errorCode = {
  UNAUTHENTICATED: 'UNAUTHENTICATED'
}

export const AUTHENTICATE_FAIL = {
  code: 'AUTHENTICATE_FAIL',
  message: 'Email or password do not match.'
}
export const INVALID_TOKEN = 'INVALID_TOKEN'

export const API_ERROR = {
  code: 'INTERVAL_ERROR',
  message: 'Error occur when processing request.'
}
