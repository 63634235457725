import React, { memo } from 'react'

const AccessDenied = (props) => {
  return (
    <div className='middle-box text-center loginscreen animated fadeInDown'>
      <h1>403</h1>
      <h3 className='font-bold'>Access Denied</h3>
      <div>
        You don't have permission to open this page. Try logging out and login
        with difference account.
      </div>
    </div>
  )
}

export default memo(AccessDenied)
