import React, { memo, useRef, createRef } from 'react'
// lib
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
// component
import CustomButtonHeader from './CustomButtonHeader'

const FormModal = (props) => {
  const {
    isMobile,
    title,
    open,
    customClass,
    handleCloseModal,
    hasButtonHeader
  } = props

  const buttonRef = useRef(createRef())

  const initPropsToComponent = props.children
    ? React.cloneElement(props.children, {
        buttonSubmit: (
          <button ref={buttonRef} type='submit' className='d-none' />
        )
      })
    : null

  const generateButton = () => (
    <CustomButtonHeader isMobile={isMobile}>
      <Button
        type='primary'
        onClick={() => {
          if (buttonRef.current) {
            buttonRef.current.click()
          }
        }}
        ghost={true}
      >
        Save
      </Button>
      <Button
        className='border-danger ml-3'
        type='danger'
        onClick={() => {
          if (handleCloseModal) {
            handleCloseModal()
          }
        }}
        ghost={true}
      >
        Cancel
      </Button>
    </CustomButtonHeader>
  )

  return (
    <Modal
      width={920}
      className={classNames({
        [`${customClass}`]: !!customClass
      })}
      centered={true}
      closable={false}
      footer={generateButton()}
      maskClosable={false}
      title={
        hasButtonHeader ? (
          <div className='d-flex align-items-center justify-content-between'>
            <div>{title}</div>
            {generateButton()}
          </div>
        ) : (
          title
        )
      }
      visible={open}
    >
      {initPropsToComponent}
    </Modal>
  )
}

FormModal.propTypes = {
  isMobile: PropTypes.oneOfType([PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  customClass: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func,
  hasButtonHeader: PropTypes.func
}

export default memo(FormModal)
