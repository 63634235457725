import jwt from 'jsonwebtoken'
export const APP_SECRET =
  process.env.REACT_APP_SECRET || 'APBxxIU5^CsjDNc$oF1r7FW/i%a4gPtL'

export const verifyToken = (token) => {
  try {
    return jwt.verify(token, APP_SECRET)
  } catch (error) {
    throw new Error(error)
  }
}
