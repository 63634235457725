import ActionTypes from './types'
import { v4 as uuidv4 } from 'uuid'
import { CHANGE_READY_STYLE } from '../reducers/readyStyles'
import readyStyleClause from './queries/readyStyle'
import readyStyleWhCls from '../clauseSearch/readyStyle'

import axiosProvider from './api/axiosProvider'
import { GRAPHQL_PATH } from '../constants'
import Notifications from 'react-notification-system-redux'
import { loadSuccess } from './commonActions'

export const intNewReadyStyle = () => {
  return {
    id: null,
    name: 'New style'
    // featureStyle: []
  }
}

const readyStyleActions = {
  loadReadyStyles(queryClause) {
    return async (dispatch) => {
      try {
        const query = readyStyleClause.loadDataPager(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${query}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.readyStyles ?? []
          //console.log('response.data?.data..........', response.data?.data)
          if (result.length > 0) {
            await dispatch({
              type: ActionTypes.LOAD_READY_STYLE,
              payload: {
                data: result
              }
            })
            dispatch(this.setActiveReadyStyle(result[0]?.index))
          } else {
            dispatch(this.addCurrentReadyStyle(1))
          }
        } else {
          dispatch(this.addCurrentReadyStyle(1))
        }
      } catch (error) {
        dispatch(this.addCurrentReadyStyle(1))
      }
    }
  },

  saveReadyStyles(name, style) {
    return async (dispatch, getState) => {
      try {
        // save current style
        await dispatch(this.changeReadyStyle(name, style))

        // int query save tabs
        const { readyStyles } = getState().readyStyle

        const queryClause = readyStyleWhCls.initQuerySaveReadyStyles(
          readyStyles
        )

        const query = readyStyleClause.create(queryClause)

        //console.log('query...............', query)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${query}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.createReadyStyles ?? []
          dispatch(
            loadSuccess({
              actionType: ActionTypes.CREATE_READY_STYLE_SUCCESS,
              data: result,
              callback: [
                Notifications.success({
                  title: 'Success',
                  message: 'Create data success.'
                })
              ]
            })
          )
        } else {
          dispatch(
            Notifications.error({
              title: `Error`,
              message: `${response.data.errors.toString()}`
            })
          )
        }
      } catch (e) {
        console.log('e................', e)
        dispatch(
          Notifications.error({
            title: `Error`,
            message: `${e.toString()}`
          })
        )
      }
    }
  },

  addCurrentReadyStyle(addition) {
    return async (dispatch, getState) => {
      try {
        // save tabs
        dispatch({
          type: ActionTypes.ADD_NEW_READY_STYLE,
          payload: { data: { data: intNewReadyStyle(), addition } }
        })
      } catch (e) {}
    }
  },

  removeCurrentReadyStyle(index) {
    return async (dispatch, getState) => {
      try {
        const { readyStyles } = getState().readyStyle
        let currentStyle = null
        if (readyStyles?.length > 0) {
          currentStyle = readyStyles.find(
            (style) => style.index?.toString() === index?.toString()
          )
        }
        if (!currentStyle || !currentStyle.id) {
          dispatch({
            type: ActionTypes.REMOVE_CURRENT_READY_STYLE,
            payload: { data: index }
          })
        } else {
          const queryClause = readyStyleWhCls.initQueryDeleteStyle(
            currentStyle.id
          )

          const query = readyStyleClause.delete(queryClause)

          //console.log('query...............', query)

          const response = await axiosProvider().post(GRAPHQL_PATH, {
            query: `${query}`,
            variables: null
          })

          if (response.status === 200 && !response.data.errors) {
            dispatch(
              loadSuccess({
                actionType: ActionTypes.REMOVE_CURRENT_READY_STYLE,
                data: index,
                callback: [
                  Notifications.success({
                    title: 'Delete',
                    message: 'Remove style successfully.'
                  })
                ]
              })
            )
          } else {
            dispatch(
              Notifications.error({
                title: `Error`,
                message: `${response.data.errors.toString()}`
              })
            )
          }
        }
      } catch (e) {
        console.log('e................', e)
        dispatch(
          Notifications.error({
            title: `Error`,
            message: `${e.toString()}`
          })
        )
      }
    }
  },

  saveCurrentReadyStyle(index, name) {
    return (dispatch) => {
      dispatch({
        type: ActionTypes.SAVE_CURRENT_READY_STYLE,
        payload: { data: { index, name } }
      })
    }
  },

  setActiveReadyStyle(index) {
    return (dispatch) => {
      dispatch({
        type: ActionTypes.SET_ACTIVE_READY_STYLE,
        payload: { data: index }
      })
    }
  },

  // addCurrentJob(product) {
  //   return (dispatch) => {
  //     dispatch({
  //       type: ActionTypes.ADD_CURRENT_JOB,
  //       payload: { data: initCurrentJob(product) }
  //     })
  //   }
  // },

  // removeCurrentJob(currentJob) {
  //   return (dispatch) => {
  //     dispatch({
  //       type: ActionTypes.REMOVE_CURRENT_JOB,
  //       payload: { data: currentJob }
  //     })
  //   }
  // },
  //
  // changePropertyItem(currentJob) {
  //   return (dispatch) => {
  //     dispatch({
  //       type: ActionTypes.CHANGE_CURRENT_JOB,
  //       payload: { data: currentJob }
  //     })
  //   }
  // },

  changeReadyStyle(name, style) {
    return (dispatch) => {
      dispatch({
        type: CHANGE_READY_STYLE,
        payload: { data: { name, style } }
      })
    }
  }
}

export default readyStyleActions
