export default {
  loadDataPager(queryClause) {
    return `
    query {
      featureProducts(${queryClause.whereClause}) {
        id
        name
        description
        images
        isDeleted
        isPublished
        order
        feature {
          id
          id
        }
        tierThree(order: "order") {
          id
          order
          finishList {
            id
            name
          }
        }
      }
    }    
    `
  },

  loadData(queryClause) {
    return `
    query {
      featureProduct(${queryClause}) {
        id
        name
        images
        description
        isDeleted
        isPublished
        order
        feature {
          id
        }
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createFeatureProduct(${queryClause}) {
        id
      }
    }
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateFeatureProduct(${queryClause}) {
        id
      }
    }    
    `
  }
}
