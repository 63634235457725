export default {
  loadDataPager(queryClause) {
    return `
    query {
      categories(${queryClause.whereClause}) {
        id
        name
        isDeleted
        order
        parent {
          name
        }
      }
      categoriesConnection(${queryClause.whereConnectionClause}) {
        fullCount
        edges {
          cursor
        }
      }
    }    
    `
  },

  loadData(queryClause) {
    return `
    query {
      category(${queryClause}) {
        id
        name
        order
        parent {
          id
          name
        }
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createCategory(${queryClause}) {
        id
      }
    }
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateCategory(${queryClause}) {
        id
      }
    }    
    `
  }
}
