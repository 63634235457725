import React, {useCallback, useEffect, useState} from 'react'
// lib
import {useDispatch, useSelector} from 'react-redux'
import {Menu, Dropdown, Avatar, Badge} from 'antd'
// action
import authActions from '../../../actions/authActions'
import stringExtensions from '../../../extensions/string'
// component
import FormModal from '../../FormModal'
import ChangePasswordForm from '../../../views/ChangePassword/Form'
import ChangePasscodeForm from '../../../views/ChangePassword/FormPasscode'
import {selectUserLogin} from '../../../reselect/auth'
import {getUserEmail, getUserName} from '../../../services/user'
import {CODE_PASSCODE, GUEST_EMAIL_ACC} from '../../../constants'
import {selectChangePasswordState} from '../../../reselect/common'
import {MENU_PROFILE} from '../../../constants/menu'

const ProfileMenu = () => {
  const [openModal, setOpenModal] = useState(false)
  const [isChangePasscode, setIsChangePassCode] = useState(false)
  const [isGuestPass, setIsGuestPass] = useState(false)

  const user = useSelector(selectUserLogin())
  const isChangePass = useSelector(selectChangePasswordState())

  // map dispatch from redux to local
  const dispatch = useDispatch()

  const onLogoutCallback = useCallback(() => dispatch(authActions.logout()), [
    dispatch
  ])

  const changePasswordCallback = useCallback(
    (queryClause) => dispatch(authActions.changePassword(queryClause)),
    [dispatch]
  )

  const changeUserPasswordCallback = useCallback(
    (queryClause) => dispatch(authActions.changeUserPassword(queryClause)),
    [dispatch]
  )

  const changeUserPassCodeCallback = useCallback(
    (queryClause) => dispatch(authActions.changePassCode(queryClause)),
    [dispatch]
  )

  const handleResetChangePassword = useCallback(
    () => dispatch(authActions.resetChangePassword()),
    [dispatch]
  )

  useEffect(() => {
    handleResetChangePassword()
  }, [])

  useEffect(() => {
    if (isChangePass) {
      handleCloseModal()
    }
  }, [isChangePass])

  const handleLogout = () => {
    onLogoutCallback()
  }

  const handleChangePassword = (values) => {
    const newPassword = stringExtensions.removeEscapeCharacter(
      values.newPassword
    )
    if (!values.isGuestPass) {
      const oldPassword = stringExtensions.removeEscapeCharacter(
        values.oldPassword
      )

      const queryClause = `oldPassword: "${oldPassword}", newPassword: "${newPassword}"`
      changePasswordCallback(queryClause)
    } else {
      const queryClause = `email: "${GUEST_EMAIL_ACC}", password: "${newPassword}"`
      changeUserPasswordCallback(queryClause)
    }
  }

  const handleChangePassCode = (values) => {
    const queryClause = `data: {value: "${values.newPasscode}"}, where: { code: "${CODE_PASSCODE}" }`
    changeUserPassCodeCallback(queryClause)
    setOpenModal(false)
  }

  const handleClickChangePassword = (isGuest) => {
    setIsGuestPass(!!isGuest)
    setIsChangePassCode(false)
    setOpenModal(true)
  }

  const handleClickChangePasscode = () => {
    setIsChangePassCode(true)
    setIsGuestPass(false)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setIsGuestPass(false)
    handleResetChangePassword()
  }

  const handleClickMenu = (key) => {
    switch (key) {
      case MENU_PROFILE.ADMIN_PASS?.key: {
        handleClickChangePassword(false)
        break
      }
      case MENU_PROFILE.GUEST_PASS?.key: {
        handleClickChangePassword(true)
        break
      }
      case MENU_PROFILE.PASSCODE?.key: {
        handleClickChangePasscode()
        break
      }
      case MENU_PROFILE.LOGOUT?.key: {
        handleLogout()
        break
      }
      default:
        break
    }
  }

  let menuProfiles = []
  Object.values(MENU_PROFILE).map((menu, index) => {
    menuProfiles.push(menu)
    if (index !== Object.keys(MENU_PROFILE).length - 1) {
      menuProfiles.push({
        divider: true
      })
    }
  })
  // render
  return (
    <div className='topbar__dropdown d-inline-block'>
      <Dropdown
        overlay={
          <Menu selectable={false} onClick={({key}) => handleClickMenu(key)}>
            <Menu.Item>
              <div className='rfq__widget__system-status__item'>
                <strong>Hello, {getUserName(user)}</strong>
              </div>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item>
              <div className='rfq__widget__system-status__item'>
                <strong>Email:</strong>
                {getUserEmail(user)}
              </div>
            </Menu.Item>
            <Menu.Divider/>
            {menuProfiles.map((menu, index) => {
              if (menu.divider) {
                return <Menu.Divider key={index}/>
              }
              return (
                <Menu.Item key={menu.key}>
                  <div className='rfq__widget__system-status__item'>
                    {menu.name}
                  </div>
                </Menu.Item>
              )
            })}
          </Menu>
        }
        trigger={['click']}
      >
        <a className='ant-dropdown-link' href='/'>
          <Avatar size='large' className='text-uppercase' shape='square'>
            {stringExtensions.getAbbreviate(getUserName(user))}
          </Avatar>
        </a>
      </Dropdown>
      <FormModal
        customClass='modal-lg'
        open={openModal}
        handleCloseModal={handleCloseModal}
        title={`Change ${isChangePasscode ? 'Passcode' : `${isGuestPass ? 'Guest' : 'Admin'} Password`}`}
      >
        {
          isChangePasscode ? (
            <ChangePasscodeForm
              isModal={true}
              handleSubmitForm={handleChangePassCode}
            />
          ) : (
            <ChangePasswordForm
              isModal={true}
              isGuestPass={isGuestPass}
              handleSubmitForm={handleChangePassword}
            />
          )
        }
      </FormModal>

    </div>
  )
}

export default ProfileMenu
