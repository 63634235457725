import {DEFAULT_THUMBNAIL, IMAGE_LINK, UPLOAD_PRODUCT} from '../constants'

const DOMAIN_IMAGE = process.env.REACT_APP_API_BASE_IMAGE_URL || 'http://localhost:4000'

export const generateDomain = () => {
  return DOMAIN_IMAGE.endsWith('/') ? DOMAIN_IMAGE.slice(0, -1) : DOMAIN_IMAGE
}

export const isImageType = (fileType) =>
  fileType === 'image/jpeg' || fileType === 'image/png'

export const checkHttpUrl = (url) => {
  let pattern = /^((http|https|ftp):\/\/)/
  return pattern.test(url)
}

export const getPreviewImage = (fileName, fileExtension) =>
  checkHttpUrl(fileName)
    ? fileName
    : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${UPLOAD_PRODUCT}/${fileName}`


export const generateImages = (
  {
    object = null,
    defaultThumbnail = DEFAULT_THUMBNAIL,
    dir = UPLOAD_PRODUCT,
    field = 'images'
  }
) => {
  if (!object) return defaultThumbnail
  if (!object[field]) return defaultThumbnail
  return checkHttpUrl(object[field]) ? object[field] : `${generateDomain()}/${dir}/${object[field]}`
}

