export const DEFAULT_GUTTER = 15
export const COL_24 = 24
export const COL_20 = 20
export const COL_18 = 18
export const COL_17 = 17
export const COL_16 = 16
export const COL_14 = 14
export const COL_12 = 12
export const COL_10 = 10
export const COL_8 = 8
export const COL_7 = 7
export const COL_6 = 6
export const COL_4 = 4
export const COL_3 = 3
export const COL_2 = 2

export const DEFAULT_FORM_LAYOUT = {
  labelCol: { span: COL_8 },
  wrapperCol: { span: COL_16 }
}

export const SMALL_LABEL_LAYOUT = {
  labelCol: { span: COL_4, md: COL_8, lg: COL_4 },
  wrapperCol: { span: COL_20, md: COL_16, lg: COL_20 }
}

export const HORIZONTAL_LAYOUT = {
  labelCol: { span: COL_24 },
  wrapperCol: { span: COL_24 }
}
