export default {
  loadDataPager(queryClause) {
    return `
    query {
      features(${queryClause.whereClause}) {
        id
        name
        description
        images
        isDeleted
        isPublished
        order
        featureCategory {
          id
          name
        }
      }
    }    
    `
  }
}
