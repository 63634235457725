import { createSelector } from 'reselect'

const getData = (state) => state?.common?.loading

const getChangePass = (state) => state?.common?.changePassword

export const selectLoadingState = () =>
  createSelector([getData], (isLoading) => !!isLoading)

export const selectChangePasswordState = () =>
  createSelector([getChangePass], (isChange) => !!isChange)
