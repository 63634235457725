import { deepCopyState } from '.'

export const FETCHING_FEATURE_CATEGORY = 'feature/FETCHING_FEATURE_CATEGORY'
export const FETCHING_FEATURE_PRODUCT = 'feature/FETCHING_FEATURE_PRODUCT'
export const FETCHING_FINISH_LIST = 'feature/FETCHING_FINISH_LIST'
export const FETCHING_FEATURE = 'feature/FETCHING_FEATURE'
export const LOAD_FEATURE_CATEGORY = 'feature/LOAD_FEATURE_CATEGORY'
export const LOAD_FEATURE_PRODUCT = 'feature/LOAD_FEATURE_PRODUCT'
export const LOAD_FINISH_LIST = 'feature/LOAD_FINISH_LIST'

const initState = {
  loadingFC: false,
  loadingFP: false,
  loadingFL: false,
  categories: [],
  products: [],
  finishLists: [],
  categorySelected: [],
  featureSelected: [],
  productSelected: []
}

const FeatureReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCHING_FEATURE_CATEGORY: {
      return Object.assign({}, state, {
        loadingFC: !!action.payload.data
      })
    }

    case FETCHING_FEATURE_PRODUCT: {
      return Object.assign({}, state, {
        loadingFP: !!action.payload.data
      })
    }

    case FETCHING_FINISH_LIST: {
      return Object.assign({}, state, {
        loadingFL: !!action.payload.data
      })
    }

    case LOAD_FEATURE_CATEGORY: {
      let categories = action.payload.data
      if (!categories || categories.length === 0) {
        categories = []
      }

      return Object.assign({}, state, {
        categories,
        loadingFC: false
      })
    }

    case LOAD_FEATURE_PRODUCT: {
      if (!action.payload.data) return state
      const { featureId, featureProducts } = action.payload.data

      let featureSelected = deepCopyState(state.featureSelected)

      const products = state.products ?? []

      if (!featureSelected || featureSelected.length === 0) {
        featureSelected = [featureId]
      } else {
        if (featureSelected.indexOf(featureId) < 0) {
          featureSelected.push(featureId)
        }
      }

      if (featureProducts?.length > 0) {
        featureProducts.forEach((fp) => {
          if (products.findIndex((p) => p.id === fp.id) < 0) {
            products.push(fp)
          }
        })
      }

      return Object.assign({}, state, {
        products,
        featureSelected,
        loadingFP: false
      })
    }

    case LOAD_FINISH_LIST: {
      if (!action.payload.data) return state
      const { productId, finishList } = action.payload.data

      let productSelected = deepCopyState(state.productSelected)

      if (!productSelected || productSelected.length === 0) {
        productSelected = [productId]
      } else {
        if (productSelected.indexOf(productId) < 0) {
          productSelected.push(productId)
        }
      }
      const finishLists = state.finishLists ?? []

      if (finishList?.length > 0) {
        finishList.forEach((fl) => {
          if (
            finishLists.findIndex(
              (p) => p.id === fl.id && p.productId === productId
            ) < 0
          ) {
            finishLists.push({
              ...fl,
              productId
            })
          }
        })
      }

      return Object.assign({}, state, {
        finishLists,
        productSelected,
        loadingFL: false
      })
    }

    default: {
      return state
    }
  }
}

export default FeatureReducer
