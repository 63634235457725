import { GUEST } from '../constants'

export const getUserName = (user) => {
  if (!user) {
    return GUEST
  }

  let username = ''
  if (user.firstName) {
    username += user.firstName
  }

  if (username !== '' && user.lastName) {
    username += ' '
  }

  if (user.lastName) {
    username += user.lastName
  }

  return username
}

export const getUserEmail = (user) => {
  if (!user) {
    return null
  }

  return user.email ?? null
}
