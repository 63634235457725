import Cookies from 'js-cookie'

import { EXPIRES_DAY_COOKIES } from '../constants'

export const getCookie = (name) => {
  return name ? Cookies.getJSON(name) : null
}

export const setCookie = ({ name, value, expires = EXPIRES_DAY_COOKIES }) => {
  Cookies.set(name, value, { expires: expires })
}

export const removeCookie = (name) => Cookies.remove(name)
