import React, { useEffect } from 'react'
// lib
import { withFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { Form, Input, Button } from 'antd'
import ErrorMessage from '../../components/ErrorMessage'
// extensions
import { formikExtensions, yupExtensions } from '../../extensions'
import {
  BUTTON_LOGIN,
  LABEL_CONFIRM_PASSWORD,
  LABEL_NEW_PASSWORD,
  LABEL_OLD_PASSWORD
} from '../../constants/label'
import validateError from '../../constants/validate'
import { DEFAULT_FORM_LAYOUT } from '../../constants/layout'
import { useSelector } from 'react-redux'
import {
  selectApiErrorCode,
  selectApiErrorMessage
} from '../../reselect/apiError'

const FormItem = Form.Item

const formikMap = withFormik({
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    oldPassword: yupExtensions.string.when(['isGuestPass'], {
      is: true,
      then: yupExtensions.string,
      otherwise: yupExtensions.stringRequired
    }),
    newPassword: yupExtensions.stringRequired,
    confirmPassword: yupExtensions.stringRequired.oneOf(
      [Yup.ref('newPassword')],
      validateError.PASSWORD_DO_NOT_MATCH
    )
  }),
  mapPropsToValues: ({ isGuestPass }) => ({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    error: '',
    isGuestPass: !!isGuestPass
  }),
  handleSubmit: (data, { props }) => {
    props.handleSubmitForm(data)
  },
  displayName: 'Form'
})

const PasswordForm = (props) => {
  const {
    touched,
    errors,
    values,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    isModal,
    buttonSubmit,
    isGuestPass,
    resetForm
  } = props

  const apiError = useSelector(selectApiErrorCode())
  const apiErrorMessage = useSelector(selectApiErrorMessage())

  useEffect(() => {
    resetForm()
  }, [isGuestPass])

  useEffect(() => {
    setFieldError('error', apiErrorMessage)
  }, [apiError, apiErrorMessage])

  return (
    <Form {...DEFAULT_FORM_LAYOUT} onFinish={handleSubmit}>
      {isGuestPass ? null : (
        <FormItem label={LABEL_OLD_PASSWORD}>
          <Input.Password
            visibilityToggle={true}
            className={classNames({
              'has-error': formikExtensions.checkFieldError({
                errors,
                fieldName: 'oldPassword',
                touched
              })
            })}
            placeholder={LABEL_OLD_PASSWORD}
            value={values.oldPassword}
            onChange={(input) =>
              setFieldValue('oldPassword', input.target.value)
            }
            onBlur={() => setFieldTouched('oldPassword', true)}
          />
          <ErrorMessage fieldName={'oldPassword'} />
        </FormItem>
      )}

      <FormItem label={LABEL_NEW_PASSWORD}>
        <Input.Password
          visibilityToggle={true}
          className={classNames({
            'has-error': formikExtensions.checkFieldError({
              errors,
              fieldName: 'newPassword',
              touched
            })
          })}
          placeholder={LABEL_NEW_PASSWORD}
          value={values.newPassword}
          onChange={(input) => setFieldValue('newPassword', input.target.value)}
          onBlur={() => setFieldTouched('newPassword', true)}
        />
        <ErrorMessage fieldName={'newPassword'} />
      </FormItem>
      <FormItem label={LABEL_CONFIRM_PASSWORD}>
        <Input.Password
          visibilityToggle={true}
          className={classNames({
            'has-error': formikExtensions.checkFieldError({
              errors,
              fieldName: 'confirmPassword',
              touched
            })
          })}
          placeholder={LABEL_CONFIRM_PASSWORD}
          value={values.confirmPassword}
          onChange={(input) =>
            setFieldValue('confirmPassword', input.target.value)
          }
          onBlur={() => setFieldTouched('confirmPassword', true)}
        />
        <ErrorMessage fieldName={'confirmPassword'} />
      </FormItem>

      <ErrorMessage fieldName={'error'} />

      {isModal ? (
        buttonSubmit
      ) : (
        <div className='form-actions text-center'>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button ant-btn w__150'
          >
            {BUTTON_LOGIN}
          </Button>
        </div>
      )}
    </Form>
  )
}

export default formikMap(PasswordForm)
