export default {
  loadDataPager(queryClause) {
    return `
    query {
      listMixTypes {
        id
        name
        isPublished
        type
        order
        shortDescription
        description
        isShowDefault
        defaultValue
        nextOrder
        thumbnail
        children {
          id
          name
          isPublished
          type
          order
          shortDescription
          description
          isShowDefault
          defaultValue
          nextOrder
          thumbnail
          children {
            id
            name
            isPublished
            type
            order
            shortDescription
            description
            isShowDefault
            defaultValue
            nextOrder
            thumbnail
          }
          
        }
      }
    }    
    `
  },

  loadData(queryClause) {
    return `
    query {
      product(${queryClause}) {
        id
        name
        order
        category {
          id
          name
        }
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createProduct(${queryClause}) {
        id
      }
    }
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateProduct(${queryClause}) {
        id
      }
    }    
    `
  },

  updateOrderMixType(queryClause) {
    return `
    mutation {
      upDownMixTypeOrder(${queryClause})
    }    
    `
  }
}
