import React from 'react'
// lib
import { Redirect, Route, withRouter } from 'react-router-dom'
// component
import AccessDenied from '../AccessDenied'
// routes
import { routes } from '../../routes'
import { getCookie } from '../../extensions/cookie'
import { COOKIE_AUTH } from '../../constants'

const ProtectedRoute = ({
  component: Component,
  resource,
  action,
  auth,
  config,
  isMobile,
  ...rest
}) => {
  const permission = getCookie(COOKIE_AUTH)
  return permission ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Component {...matchProps} auth={auth} isMobile={isMobile} />
      )}
    />
  ) : (
    <Redirect to={`${routes.LOGIN}?redirectURL=${rest.location.pathname}`} />
  )
}

export default withRouter(ProtectedRoute)
