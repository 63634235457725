export default {
  loadDataPager(queryClause) {
    return `
    query {
      properties(${queryClause}) {
        id
        key
        description
        shortDisplayName
        longDisplayName
        defaultValue
        isPublished
        isShowDefault
        order
        variables
        productId
        defaultInch
        variablesInches
      }
    }    
    `
  },

  loadData(queryClause) {
    return `
    query {
      property(${queryClause}) {
        id
        key
        description
        shortDisplayName
        longDisplayName
        defaultValue
        isPublished
        isShowDefault
        order
        variables
        defaultInch
        variablesInches
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createProperty(${queryClause}) {
        id
      }
    }
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateProperty(${queryClause}) {
        id
      }
    }    
    `
  }
}
