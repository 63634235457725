import axios from 'axios'
import { COOKIE_AUTH } from '../../constants'
import { getCookie } from '../../extensions/cookie'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const initAxiosHeader = () => {
  const auth = getCookie(COOKIE_AUTH)
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers':
      'Accept, X-Requested-With, Content-Type, Authorization, Access-Control-Allow-Headers',
    Authorization: auth ? `Bearer ${auth}` : undefined
  }

  return headers
}

const axiosProvider = () =>
  axios.create({
    // baseURL: API_PROXY_URL,
    baseURL: API_BASE_URL,
    // withCredentials: true,
    headers: initAxiosHeader(),
    // timeout: 30000
    timeout: 99999
  })
export default axiosProvider
