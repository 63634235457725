export const getNewDate = (time) => (time ? new Date(time) : new Date())

export const isPreviousDate = (dateCheck, dateCompare) => {
  if (!dateCheck || !dateCompare) {
    return false
  }

  return new Date(dateCheck) < new Date(dateCompare)
}

export const isFutureDate = (dateCheck, dateCompare) => {
  if (!dateCheck || !dateCompare) {
    return false
  }

  return new Date(dateCheck) > new Date(dateCompare)
}
