import React, { useEffect } from 'react'
// lib
import { withFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { Form, Input, Button } from 'antd'
import ErrorMessage from '../../components/ErrorMessage'
// extensions
import { formikExtensions, yupExtensions } from '../../extensions'
import {
  BUTTON_LOGIN,
  LABEL_CONFIRM_PASSWORD, LABEL_NEW_PASSCODE,
  LABEL_NEW_PASSWORD,
  LABEL_OLD_PASSWORD
} from '../../constants/label'
import validateError from '../../constants/validate'
import { DEFAULT_FORM_LAYOUT } from '../../constants/layout'
import { useSelector } from 'react-redux'
import {
  selectApiErrorCode,
  selectApiErrorMessage
} from '../../reselect/apiError'

const FormItem = Form.Item

const formikMap = withFormik({
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    newPasscode: yupExtensions.stringRequired
  }),
  mapPropsToValues: ({  }) => ({
    newPasscode: '',
    error: ''
  }),
  handleSubmit: (data, { props }) => {
    props.handleSubmitForm(data)
  },
  displayName: 'Form'
})

const ChangePasscodeForm = (props) => {
  const {
    touched,
    errors,
    values,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    isModal,
    buttonSubmit,
    resetForm
  } = props

  const apiError = useSelector(selectApiErrorCode())
  const apiErrorMessage = useSelector(selectApiErrorMessage())

  useEffect(() => {
    resetForm()
  }, [])

  useEffect(() => {
    setFieldError('error', apiErrorMessage)
  }, [apiError, apiErrorMessage])

  return (
    <Form {...DEFAULT_FORM_LAYOUT} onFinish={handleSubmit}>
      <FormItem label={LABEL_NEW_PASSCODE}>
        <Input.Password
          visibilityToggle={true}
          className={classNames({
            'has-error': formikExtensions.checkFieldError({
              errors,
              fieldName: 'newPasscode',
              touched
            })
          })}
          placeholder={LABEL_NEW_PASSCODE}
          value={values.newPasscode}
          onChange={(input) => setFieldValue('newPasscode', input.target.value)}
          onBlur={() => setFieldTouched('newPasscode', true)}
        />
        <ErrorMessage fieldName={'newPasscode'} />
      </FormItem>

      {isModal ? (
        buttonSubmit
      ) : (
        <div className='form-actions text-center'>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button ant-btn w__150'
          >
            {BUTTON_LOGIN}
          </Button>
        </div>
      )}
    </Form>
  )
}

export default formikMap(ChangePasscodeForm)
