// lib
import Notifications from 'react-notification-system-redux'
import { API_ERROR } from '../constants/error'
import { objectExtensions } from '../extensions'
import { loadFail } from './commonActions'
import ActionTypes from './types'

export const setErrorFromApi = (error) => {
  return (dispatch) => {
    dispatch(
      Notifications.error({
        title: `Error`,
        message: `${error?.message ?? API_ERROR.message}`
      })
    )

    dispatch(loadFail(error))

    const { code, message, extensionInfo } = objectExtensions.getErrorCode(
      error
    )

    dispatch(setFormFieldError(code, message, extensionInfo))
  }
}

export const showSuccessNotification = (message) => {
  return (dispatch) => {
    dispatch(
      Notifications.success({
        title: `Success`,
        message: message
      })
    )
  }
}

export const setFormFieldError = (
  errorCode,
  errorMessage,
  extensionInfo = null
) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.FETCH_FORM_FIELD_ERROR_SUCCESS,
      payload: {
        code: errorCode,
        message: errorMessage,
        extensionInfo: extensionInfo
      }
    })
  }
}

export const resetFormFieldError = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.FETCH_RESET_FORM_FIELD_ERROR_SUCCESS
    })
  }
}
