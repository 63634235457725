const ActionTypes = {
  REDIRECT_URL: 'fetching/REDIRECT_URL',
  // fetching
  START_FETCHING: 'fetching/START_FETCHING',
  FETCH_SUCCESS: 'fetching/FETCH_SUCCESS',
  START_FETCHING_SELECT: 'fetching/START_FETCHING_SELECT',
  STOP_FETCHING_SELECT: 'fetching/STOP_FETCHING_SELECT',
  FETCH_PAGER_SUCCESS: 'fetching/FETCH_PAGER_SUCCESS',
  CREATE_SUCCESS: 'fetching/CREATE_SUCCESS',
  UPDATE_SUCCESS: 'fetching/UPDATE_SUCCESS',
  DELETE_SUCCESS: 'fetching/DELETE_SUCCESS',
  UPDATE_MANY_SUCCESS: 'fetching/UPDATE_MANY_SUCCESS',
  // common
  CLEAR_SUCCESS: 'fetching/CLEAR_SUCCESS',
  STOP_FETCHING: 'fetching/STOP_FETCHING',
  CHANGE_PASSWORD_SUCCESS: 'fetching/CHANGE_PASSWORD_SUCCESS',
  RESET_CHANGE_PASSWORD_SUCCESS: 'fetching/RESET_CHANGE_PASSWORD_SUCCESS',

  // notification
  SHOW_NOTIFICATION: 'notification/SHOW_NOTIFICATION',
  RESET_NOTIFICATION: 'notification/RESET_NOTIFICATION',

  // form
  FETCH_FORM_FIELD_ERROR_SUCCESS: 'form/FETCH_FORM_FIELD_ERROR',
  FETCH_RESET_FORM_FIELD_ERROR_SUCCESS:
    'form/FETCH_RESET_FORM_FIELD_ERROR_SUCCESS',

  // product
  FETCHING_PRODUCT_SUCCESS: 'product/FETCHING_PRODUCT_SUCCESS',

  UPDATE_CATEGORY_SUCCESS: 'product/UPDATE_CATEGORY_SUCCESS',
  UPDATE_PRODUCT_SUCCESS: 'product/UPDATE_PRODUCT_SUCCESS',
  UPDATE_PROPERTY_SUCCESS: 'product/UPDATE_PROPERTY_SUCCESS',

  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  REFRESH_TOKEN: 'REFRESH_TOKEN',

  //tabs
  LOAD_READY_STYLE: 'fetching/LOAD_READY_STYLE',
  CLEAR_READY_STYLE: 'fetching/CLEAR_READY_STYLE',
  ADD_NEW_READY_STYLE: 'fetching/ADD_NEW_READY_STYLE',
  REMOVE_CURRENT_READY_STYLE: 'fetching/REMOVE_CURRENT_READY_STYLE',
  SAVE_CURRENT_READY_STYLE: 'fetching/SAVE_CURRENT_READY_STYLE',
  SET_ACTIVE_READY_STYLE: 'fetching/SET_ACTIVE_READY_STYLE',
  CREATE_READY_STYLE_SUCCESS: 'fetching/CREATE_READY_STYLE_SUCCESS',
}

export default ActionTypes
