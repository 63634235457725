import React from 'react'

export default {
  formatNumber(number, nullable) {
    if (number > 0) {
      return number
    }
    if (!nullable) {
      return 0
    }
    return null
  }
}
