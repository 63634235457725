import {
  FETCHING_FEATURE_CATEGORY,
  FETCHING_FEATURE_PRODUCT,
  FETCHING_FINISH_LIST,
  LOAD_FEATURE_CATEGORY,
  LOAD_FEATURE_PRODUCT,
  LOAD_FINISH_LIST
} from '../reducers/feature'
import axiosProvider from './api/axiosProvider'
import featureClause from './queries/featureCategory'

const GRAPHQL_PATH = ''

const featureActions = {
  fetchingListFeatureCategory(loading) {
    return (dispatch) =>
      dispatch({
        type: FETCHING_FEATURE_CATEGORY,
        payload: {
          data: !!loading
        }
      })
  },

  fetchingListFeatureProduct(loading) {
    return (dispatch) =>
      dispatch({
        type: FETCHING_FEATURE_PRODUCT,
        payload: {
          data: !!loading
        }
      })
  },

  fetchingFinishList(loading) {
    return (dispatch) =>
      dispatch({
        type: FETCHING_FINISH_LIST,
        payload: {
          data: !!loading
        }
      })
  },

  loadFeatureCategory() {
    return async (dispatch, getState) => {
      try {
        dispatch(this.fetchingListFeatureCategory(true))
        const query = featureClause.loadAllFeatureCategories()
        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${query}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.menuFeatureCategories ?? []
          dispatch({
            type: LOAD_FEATURE_CATEGORY,
            payload: {
              data: result
            }
          })

          const { featureSelected } = getState().feature ?? {}
          if (
            (!featureSelected || featureSelected.length === 0) &&
            result?.length > 0
          ) {
            const category = result[0].featureCategory
            if (category.features?.length > 0) {
              dispatch(this.loadProductByFeature(category.features[0].id, true))
            }
          }
        } else {
          console.log('loadCategories.... ', response)
          dispatch({
            type: LOAD_FEATURE_CATEGORY,
            payload: {
              data: []
            }
          })
          dispatch(this.fetchingListFeatureCategory(false))
        }
      } catch (error) {
        console.log('loadCategories fail... ', error)
        dispatch({
          type: LOAD_FEATURE_CATEGORY,
          payload: {
            data: []
          }
        })
        dispatch(this.fetchingListFeatureCategory(false))
      }
    }
  },

  loadProductByFeature(featureId, isDefault = false) {
    return async (dispatch, getState) => {
      try {
        console.log('featureId........', featureId)
        const { featureSelected } = getState().feature || {}

        if (
          !featureSelected ||
          featureSelected.length === 0 ||
          featureSelected.indexOf(featureId) < 0
        ) {
          dispatch(this.fetchingListFeatureProduct(true))
          const query = featureClause.loadProductByFeature(featureId)
          const response = await axiosProvider().post(GRAPHQL_PATH, {
            query: `${query}`,
            variables: null
          })

          if (response.status === 200 && !response.data.errors) {
            const result = response.data?.data?.featureProducts
            dispatch({
              type: LOAD_FEATURE_PRODUCT,
              payload: {
                data: {
                  featureId,
                  featureProducts: result
                }
              }
            })

            if (isDefault) {
              if (result.length > 0) {
                const productId = result[0].id
                dispatch(this.loadFinishListByProduct(productId))
              }
            }
          } else {
            console.log('loadProductByCategory.... ', response)
            dispatch(this.fetchingListFeatureProduct(false))
          }
        }
      } catch (error) {
        console.log('loadProductByCategory fail... ', error)

        dispatch(this.fetchingListFeatureProduct(false))
      }
    }
  },

  loadFinishListByProduct(productId) {
    return async (dispatch, getState) => {
      try {
        const { productSelected } = getState().feature || {}

        if (
          !productSelected ||
          productSelected.length === 0 ||
          productSelected.indexOf(productId) < 0
        ) {
          dispatch(this.fetchingFinishList(true))
          const query = featureClause.loadFinishListByProduct(productId)
          const response = await axiosProvider().post(GRAPHQL_PATH, {
            query: `${query}`,
            variables: null
          })

          if (response.status === 200 && !response.data.errors) {
            const result = response.data?.data?.searchFinishListByProduct ?? []
            dispatch({
              type: LOAD_FINISH_LIST,
              payload: {
                data: {
                  productId,
                  finishList: result
                }
              }
            })
          } else {
            console.log('loadProductByCategory.... ', response)
            dispatch(this.fetchingFinishList(false))
          }
        }
      } catch (error) {
        console.log('loadProductByCategory fail... ', error)

        dispatch(this.fetchingFinishList(false))
      }
    }
  }
}

export default featureActions
