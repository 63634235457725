import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/es/integration/react'
import { Route, Switch } from 'react-router-dom'

import configStore from './configStore'
import Layout from './components/Layout'
import mainRoute from './routes/mainRoute'
import AuthLayout from './components/Layout/AuthLayout'

const App = () => {
  return (
    <Provider store={configStore.store}>
      <PersistGate persistor={configStore.persistor}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <ConnectedRouter history={configStore.history}>
          {/* <ScrollToTop> */}
          <Switch key={'switch'}>
            <Route path={mainRoute.AUTHENTICATE} component={AuthLayout} />
            <Route path={mainRoute.HOME_PAGE} component={Layout} />
          </Switch>

          {/* </ScrollToTop> */}
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  )
}
export default App
