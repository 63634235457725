import { default as ActionTypes } from '../actions/types'

const initialState = {
  code: null,
  message: null,
  extensionInfo: null
}

const ApiErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_FORM_FIELD_ERROR_SUCCESS: {
      return Object.assign({}, state, { ...action.payload })
    }
    case ActionTypes.FETCH_RESET_FORM_FIELD_ERROR_SUCCESS: {
      return initialState
    }
    default: {
      return initialState
    }
  }
}

export default ApiErrorReducer
