import { queryStringExtensions, stringExtensions } from '../extensions'

export default {
  initQuerySearchReadyStyle(values = {}) {
    let query = ``
    if (values.keyword) {
      const keyword = stringExtensions.removeEscapeCharacter(values.keyword)
      query += `, name: {like: "%${keyword}%"}`
    }

    let order = `index`
    return {
      whereClause: `where: {${query}}, order: "${order}"`,
      whereConnectionClause: `where: {${query}}, first: 1`
    }
  },

  initQuerySaveReadyStyles(values = []) {
    let query = '['
    if (values.length > 0) {
      values.map((item, key) => {
        query += `{`
        const name = stringExtensions.removeEscapeCharacter(item.name)
        query += `, name: "${name}"`
        query += `, index: ${Number(item.index)}`
        if (item.style) {
          const style = stringExtensions.removeEscapeCharacter(
            JSON.stringify(item.style)
          )
          query += `, currentStyle: "${style}"`
        }
        query += `}`
      })
    }
    query += ']'
    return `data: ${query}`
  },

  initQueryDeleteStyle(id) {
    return `data: {id: "${stringExtensions.removeEscapeCharacter(id)}"}`
  }
}
