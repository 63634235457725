import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { navAuth } from '../../routes'

const AuthLayout = (props) => {
  return (
    <div
      className={'layout'}
      style={{
        backgroundImage:
          'url(https://desktopwalls.net/wp-content/uploads/2014/12/Hipster%20Items%20Minimal%20Flat%20Illustration%20Desktop%20Wallpaper.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className='header'></div>
      <div className='content'>
        <div className='title login-heading text-center'>
          <h1 className='text-uppercase'>
            <strong>Cabinet Login Admin</strong>
          </h1>
        </div>
        <div className='block'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className={'inner'}>
                <Switch key={'auth'}>
                  {navAuth.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      component={route.component}
                      exact={route.isExactly}
                    />
                  ))}
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`footer text-center`}>
        <ul className='list-unstyled list-inline mb-3'>
          <li className='list-inline-item'>
            <a>Terms of Use</a>
          </li>
          <li className='active list-inline-item'>
            <a>Compliance</a>
          </li>
          <li className='list-inline-item'>
            <a>Confidential Information</a>
          </li>
          <li className='list-inline-item'>
            <a>Support</a>
          </li>
          <li className='list-inline-item'>
            <a>Contacts</a>
          </li>
        </ul>
        <p>&copy; {new Date().getFullYear()} Cabinet. All rights reserved.</p>
      </div>
    </div>
  )
}

export default AuthLayout
