export const CATEGORY_PATH = 'category'

export const PRODUCT_PATH = 'product'

export const PROPERTY_PATH = 'property'

export const FEATURE_PATH = 'feature'

export const FEATURE_PRODUCT_PATH = 'featureProduct'

export const FEATURE_CATEGORY_PATH = 'featureCategory'

export const MENU_FEATURE_PATH = 'menuFeature'

export const FINISH_LIST_PATH = 'finishList'

// SELECT

export const SELECT_CATEGORY_PATH = 'selectCategory'

export const SELECT_FINISH_LIST_PATH = 'selectFinishList'
