// lib
import Notifications from 'react-notification-system-redux'
import ActionTypes from './types'
import { routes } from '../routes'
import { loadFail, loadSuccess, redirectPath } from './commonActions'
import axiosProvider from './api/axiosProvider'
import { COOKIE_AUTH, COOKIE_REFRESH_AUTH, GRAPHQL_PATH } from '../constants'
import { objectExtensions } from '../extensions'
import {
  setErrorFromApi,
  setFormFieldError,
  showSuccessNotification
} from './formActions'
import authQuery from './queries/auth'
import { verifyToken } from '../extensions/jwt'
import { removeCookie, setCookie } from '../extensions/cookie'
import {
  CHANGE_PASSCODE_SUCCESS,
  CHANGE_PASSWORD_SUCCESS
} from '../constants/message'

export default {
  logout() {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.USER_LOGGED_OUT
      })
      removeCookie(COOKIE_AUTH)
      removeCookie(COOKIE_REFRESH_AUTH)
      redirectPath(routes.LOGIN)
    }
  },

  login(queryClause, pathRedirect) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.login(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.loginAdmin ?? null
          const token = verifyToken(result.token)
          if (token) {
            setCookie({
              name: COOKIE_AUTH,
              value: result.token,
              expires: new Date(result.expiresIn)
            })
            setCookie({
              name: COOKIE_REFRESH_AUTH,
              value: result.refreshToken,
              expires: new Date(result.refreshExpiresIn)
            })
            dispatch(
              loadSuccess({
                actionType: ActionTypes.USER_LOGGED_IN,
                data: {
                  user: token?.user,
                  expiresIn: result.expiresIn
                },
                pathRedirect: pathRedirect
              })
            )
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  refreshToken(queryClause) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.refreshToken(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.refreshToken ?? null
          const token = verifyToken(result.token)
          if (token) {
            setCookie({
              name: COOKIE_AUTH,
              value: result.token,
              expires: new Date(result.expiresIn)
            })
            setCookie({
              name: COOKIE_REFRESH_AUTH,
              value: result.refreshToken,
              expires: new Date(result.refreshExpiresIn)
            })
            dispatch(
              loadSuccess({
                actionType: ActionTypes.REFRESH_TOKEN,
                data: result.expiresIn
              })
            )
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  changePassword(queryClause) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.changePassword(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.changePassword
          dispatch(
            loadSuccess({
              actionType: ActionTypes.CHANGE_PASSWORD_SUCCESS,
              data: result,
              callback: result ? [this.logout()] : []
            })
          )
          if (result) {
            dispatch(this.logout())
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  changeUserPassword(queryClause) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.changeUserPassword(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.changeUserPassword
          if (result) {
            dispatch(
              loadSuccess({
                actionType: ActionTypes.CHANGE_PASSWORD_SUCCESS,
                data: true,
                callback: [showSuccessNotification(CHANGE_PASSWORD_SUCCESS)]
              })
            )
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  changePassCode(queryClause) {
    return async (dispatch) => {
      try {
        const queryData = authQuery.changePassCode(queryClause)

        const response = await axiosProvider().post(GRAPHQL_PATH, {
          query: `${queryData}`,
          variables: null
        })

        if (response.status === 200 && !response.data.errors) {
          const result = response.data?.data?.updateSetting
          if (result) {
            dispatch(
              loadSuccess({
                actionType: ActionTypes.CHANGE_PASSWORD_SUCCESS,
                data: true,
                callback: [showSuccessNotification(CHANGE_PASSCODE_SUCCESS)]
              })
            )
          } else {
            dispatch(loadFail('Error occur when processing'))
          }
        } else {
          dispatch(setErrorFromApi(response.data.errors[0]))
        }
      } catch (error) {
        dispatch(setErrorFromApi(error.response))
      }
    }
  },

  resetChangePassword() {
    return (dispatch) => {
      dispatch({
        type: ActionTypes.RESET_CHANGE_PASSWORD_SUCCESS
      })
    }
  }
}
