export default {
  loadDataPager(queryClause) {
    return `
    query {
      finishLists(${queryClause.whereClause}) {
        id
        name
        description
        isDeleted
        isPublished
        order
        parentId
        children(where: { isDeleted: false }, order: "order") {
          id
          name
          description
          isDeleted
          isPublished
          parentId
          images
        }
      }
      finishListsConnection(${queryClause.whereConnectionClause}) {
        fullCount
        edges {
          cursor
        }
      }
    }    
    `
  },

  loadData(queryClause) {
    return `
    query {
      finishList(${queryClause}) {
        id
        name
        description
        isDeleted
        isPublished
        order
        parentId
        parent(where: { isDeleted: false }) {
          id
          name
        }
      }
    }    
    `
  },

  create(queryClause) {
    return `
    mutation {
      createFinishList(${queryClause}) {
        id
      }
    }    
    `
  },

  update(queryClause) {
    return `
    mutation {
      updateFinishList(${queryClause}) {
        id
      }
    }    
    `
  }
}
