import { stringExtensions } from '../../extensions'

export default {
  loadDataPager(queryClause){
    return `
    query {
      featureCategories(${queryClause}) {
        id
        name
      }
    }    
    `
  },

  loadAllFeatureCategories() {
    return `
    query {
      menuFeatureCategories {
        featureCategory {
          id
          name
          features {
            id
            name
            images
            order
            description
          }
        }
        defaultStyle {
          id
          featureCategoryId
          featureId
          isDefault
          feature {
            id
            name
            description
            images
            order
          }
          featureProduct {
            id
            name
            description
            images
            order
          }
          finishList {
            id
            name
            description
            images
            order
          }
        }
      }
    }    
    `
  },

  loadProductByFeature(featureId) {
    let whCls = `isDeleted: false,  isPublished: true`
    if (featureId) {
      whCls += `, featureId: "${stringExtensions.removeAccentedCharacter(
        featureId
      )}"`
    }

    return `
    query {
      featureProducts( where: {${whCls}}, order: "order" ) {
        id
        name
        description
        images
        isDeleted
        order
        featureId
      }
    }    
    `
  },

  loadFinishListByProduct(productId) {
    let whCls = `isDeleted: false, isPublished: true `
    if (productId) {
      whCls += `, productId: "${stringExtensions.removeAccentedCharacter(
        productId
      )}"`
    }

    return `
    query {
      searchFinishListByProduct(where: { ${whCls} }) {
        id
        name
        images
        isDeleted
        isPublished
        description
      }
    }    
    `
  }
}